// Copyleaks Theme Configuration

@use '@angular/material' as mat;
@import '~@angular/material/theming';
@import './website-colors.styles.scss';
@import './cls-mat-components-overrides/cls-mat-tooltip-overrides.scss';

@include mat.core();

// Copyleaks themes palettes definitions

$copyleaks-design-v2-primary: mat.define-palette($copyleaks-primary-palette);
$copyleaks-design-v2-accent: mat.define-palette($copyleaks-primary-palette);
$copyleaks-design-v2-warn: mat.define-palette($copyleaks-warn-palette);

// Copyleaks typography definitions

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');
@import './fonts/haboro-soft-fonts.scss';
@import './fonts/open-sans/open-sans-fonts.scss';

$copyleaks-design-v2-typography: mat-typography-config(
	$font-family: 'haboro-soft, Almarai, sans-serif',
);

// Copyleaks theme object which consists of configurations for individual
// theming systems such as "color" or "typography".
$copyleaks-design-v2-theme: mat.define-light-theme(
	(
		color: (
			primary: $copyleaks-design-v2-primary,
			accent: $copyleaks-design-v2-accent,
			warn: $copyleaks-design-v2-warn,
		),
		typography: $copyleaks-design-v2-typography,
	)
);

@include mat.all-component-themes($copyleaks-design-v2-theme);

.mat-icon:not([fontSet]):not(.material-icons-outlined):not(.material-icons-sharp):not(.material-icons-two-tone) {
	font-family: 'Material Icons Round' !important;
}
