/* Common SCSS resources: variables, mixins, and reusable styles */

// Targets and hides the LastPass icon container using a specific attribute
.hide-lastpass-icon ~ div[data-lastpass-icon-root] {
	display: none;
}
// Reset all default styles to ensure consistent and accessible heading presentaion
.title-wrapper {
	all: unset;
}
