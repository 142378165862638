/* You can add global styles to this file, and also import other style files */
@import '../src/ngx-common/styles/variables.scss';
@import '../src/ngx-common/styles/media-queries.mixins.scss';
@import '../src/ngx-common/styles/custom-snackbar.scss';
@import '../src/ngx-common-v2/styles/cls-mat-design.scss';
@import '../src/ngx-common-v2/styles/website-colors.styles.scss';
@import '../src/ngx-common-v2/styles/common.styles.scss';
// app css
html,
body {
	height: 100%;
}
body {
	margin: 0;
	font-family: 'haboro-soft';
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $color-black;
	font-family: 'Almarai';
	font-style: normal;
	line-height: normal;
	text-transform: capitalize;
}
p {
	color: $color-black;
}

h1 {
	font-size: 24px;
	font-weight: 800;
}

h2 {
	font-size: 20px;
	font-weight: 700;
}

h3,
h4,
h5,
h6 {
	font-size: 18px;
	font-weight: 700;
}

.mat-menu-panel {
	max-width: unset !important;
}

.copyleaks-a-tag-black {
	color: black !important;
	text-decoration: none;
	cursor: pointer;
}

.mat-dialog-no-padding {
	.mat-dialog-container {
		padding: 0 !important;
	}
}

.mat-dialog-no-overflow {
	.mat-dialog-container {
		overflow: hidden !important;
	}
}
.copyleaks-input {
	.mat-form-field-infix {
		border-top: 0;
		width: auto;
	}
	.mat-form-field-outline {
		top: 0 !important;
		background: #f0f5f9 0% 0% no-repeat padding-box;
	}
	.mat-select-arrow-wrapper {
		vertical-align: unset;
	}
	.mat-form-field-wrapper {
		padding-bottom: 16px;
	}
	.mat-form-field-subscript-wrapper {
		margin-top: 7px;
		overflow: visible;
	}
}

.copyleaks-select {
	.mat-form-field-wrapper {
		padding-bottom: 0;
	}
	.mat-form-field-infix {
		border-top: 0;
		width: auto;
	}
	.mat-form-field-outline {
		top: 0 !important;
		background: #f0f5f9 0% 0% no-repeat padding-box;
	}
	.mat-select-arrow-wrapper {
		vertical-align: unset;
		transform: unset !important;
	}
}

.copyleaks-radio-btn {
	.mat-radio-container {
		height: 16px;
		display: flex;
		width: 16px;
	}
	.mat-radio-label {
		display: flex;
	}
	.mat-radio-label-content {
		font-size: 14px;
	}
	.mat-radio-outer-circle {
		border-color: #3f9af5;
		display: flex;
		height: 16px;
		width: 16px;
	}
	.mat-radio-inner-circle {
		height: 16px;
		width: 16px;
	}
	.mat-radio-button .mat-radio-ripple {
		height: 32px;
		width: 32px;
		left: calc(50% - 16px);
		top: calc(50% - 16px);
	}
}

.mat-dialog-container-relative-position {
	.mat-dialog-container {
		position: relative;
		height: 90vh;
		max-height: 839px;
		@include on-medium-screen {
			padding: 16px;
			height: unset;
		}
	}
}

.failed-invites-dialog-panel-class {
	max-width: 90% !important;
	.mat-dialog-container {
		@include on-medium-screen {
			padding: 16px;
		}
	}
}

.manage-repository-permision-dialog {
	.mat-dialog-container {
		padding: 0;
		border-radius: 8px;
	}
}

[no-margin-top] {
	margin-top: 0 !important;
}

[clickable] {
	cursor: pointer !important;
	outline: none !important;
}

.disabled-icon {
	opacity: 0.5;
	pointer-events: none;
}

//#region animations styles

.swing-in-top-fwd {
	-webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
	animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}
@-webkit-keyframes swing-in-top-fwd {
	0% {
		-webkit-transform: rotateX(-100deg);
		transform: rotateX(-100deg);
		-webkit-transform-origin: top;
		transform-origin: top;
		opacity: 0;
	}
	100% {
		-webkit-transform: rotateX(0deg);
		transform: rotateX(0deg);
		-webkit-transform-origin: top;
		transform-origin: top;
		opacity: 1;
	}
}
@keyframes swing-in-top-fwd {
	0% {
		-webkit-transform: rotateX(-100deg);
		transform: rotateX(-100deg);
		-webkit-transform-origin: top;
		transform-origin: top;
		opacity: 0;
	}
	100% {
		-webkit-transform: rotateX(0deg);
		transform: rotateX(0deg);
		-webkit-transform-origin: top;
		transform-origin: top;
		opacity: 1;
	}
}
//#endregion

//#region scrollbar styles

::-webkit-scrollbar {
	width: 10px;
	height: 10px !important;
}

::-webkit-scrollbar-track {
	border-radius: 10px;
	transform: matrix(0, 1, -1, 0, 0, 0);
	background: #f2f6f9 0% 0% no-repeat padding-box;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: var(--dark-gray) 0% 0% no-repeat padding-box;
	background: #c9d6df 0% 0% no-repeat padding-box;
}
//#endregion

[full-width] {
	width: 100% !important;
}

//#region angular material tooltip styles
.mat-tooltip {
	font-size: 14px;
	background-color: #52616b;
	word-break: normal !important;
	white-space: normal !important;
	text-align: center;
	border-radius: 8px !important;
}

.mat-dialog-custom-payment-dialog {
	max-width: 90vw !important;
	max-height: 95vh !important;
	.mat-dialog-container {
		padding: 0px;
	}
}
//#endregion
